@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  img {
    @apply inline-block;
  }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
      font-family: "Poppins", sans-serif;
}

a,
button {
    transition: all 0.35s ease-in-out;
}


html {
    scroll-behavior: smooth;
}

.menu-button-mobile {
    position: absolute;
    height: 70px;
    width: 20px;
    top: 100px;
    right: -20px;
    display: none;
    background-color: #C93030;
}

.menu-button-mobile div {
    height: 1px;
    width: 100%;
}

.aactions {
    transition: 0.4s ease-in-out;
}

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

.list-of-visits {
    display: none;
}

.historija-posjeta:hover .list-of-visits {
    display: flex !important;
}

@media (max-width: 1020px) {
    .arena-sidebar {
        position: absolute;
        height: 100vh;
        z-index: 9999;
        left: -550px;
        top: 0;
        width: 550px !important;
        min-width: 550px !important;
    }

    .page-container {
        width: 100vw !important;
        position: relative;
    }

    .app-form-container {
        width: 100% !important;
        min-width: 100% !important;
    }

    .menu-button-mobile {
        display: flex;
    }
}

@media (max-width: 440px) {

    .procjena-header h1,
    .procjena-header h3 {
        font-size: 12px !important;
    }
}

@media (max-width: 585px) {
    .arena-sidebar {
        width: calc(100% - 25px) !important;
        min-width: calc(100% - 25px) !important;
        left: calc(-100% + 25px);
    }
}

.zaliha-arenatable

.pneumatici-tabela {
  width: 100%;
}

.pneumatici-tabela, .pneumatici-tabela td {
  border-collapse: collapse;
}

.pneumatici-tabela thead {
  display: table; /* to take the same width as tr */
  width: calc(100% - 17px); /* - 17px because of the scrollbar width */
}

.pneumatici-tabela tbody {
  display: block; /* to enable vertical scrolling */
  max-height: 800px; /* e.g. */
  overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
}

.pneumatici-tabela th, .pneumatici-tabela td {
  width: 33.33%; /* to enable "word-break: break-all" */
  padding: 5px;
  word-break: break-all; /* 4. */
}

.pneumatici-tabela tr {
  display: table; /* display purpose; th's border */
  width: 100%;
  box-sizing: border-box; /* because of the border (Chrome needs this line, but not FF) */
}


.arena-input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}